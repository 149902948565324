<template>
  <div>
    <v-dialog v-model="loginFlag" :fullscreen="isMobile" max-width="460">
      <v-card class="login-container" style="border-radius:4px">
        <v-icon class="float-right" @click="loginFlag = false">
          mdi-close
        </v-icon>
        <div class="login-wrapper">
          <!-- 用户名 -->
          <v-text-field
            v-model="username"
            label="用户名"
            :disabled="false"
            placeholder="请输入您的用户名或手机号码"
            clearable
            @keyup.enter="login"
          />
          <!-- 密码 -->
          <v-text-field
            v-model="password"
            class="mt-7"
            label="密码"
            :disabled="false"
            placeholder="请输入您的密码"
            @keyup.enter="login"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
          />
          <!-- 按钮 -->
          <v-btn
            class="mt-7"
            block
            color="blue"
            :disabled="false"
            style="color:#fff"
            @click="login"
          >
            登录
          </v-btn>
          <!-- 注册和找回密码 -->
          <div class="mt-10 login-tip">
<!--            <router-link style="margin-right: 90px;" target="_blank" :to="{path:'/account'}">获取测试号</router-link>-->
            <span @click="openRegister">立即注册</span>
            <span @click="openForget" class="float-right">忘记密码?</span>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { setUserId, setUserName, setUserAvatar, setAuthentication } from "@/utils/cookieUtil";
import { webLogin } from "@/api/auth";
import { userinfo } from "@/api/user";
import { WEB_INDEX_LINK } from '../../utils/constants';

export default {
  data: function() {
    return {
      username: "",
      password: "",
      show: false,
      testAccount: false
    };
  },
  computed: {
    loginFlag: {
      set(value) {
        this.$store.state.loginFlag = value;
      },
      get() {
        return this.$store.state.loginFlag;
      }
    },
    isMobile() {
      const clientWidth = document.documentElement.clientWidth;
      return clientWidth <= 960;
    }
  },
  methods: {
    openRegister() {
      this.$store.state.loginFlag = false;
      this.$store.state.registerFlag = true;
    },
    openForget() {
      this.$store.state.loginFlag = false;
      this.$store.state.forgetFlag = true;
    },
    login() {
      if (!this.username) {
        this.$message.error("请输入用户名");
        return;
      }
      if (!this.password) {
        this.$message.error("请输入密码");
        return;
      }
      const form = {
        userName: this.username,
        password: this.password
      }

      webLogin(form).then(res => {
        if (res.code === 200) {
          this.$store.commit("closeModel");
          this.$message.success("登录成功！");
          setUserId(res.data.loginId);
          setAuthentication(res.data.tokenValue);
          userinfo(res.data.loginId).then(response => {
            if (response.code === 200) {
              setUserName(response.data.userName);
              setUserAvatar(response.data.avatar);
              window.location.href = WEB_INDEX_LINK;
            } else {
              this.$message.error(response.message);
            }
          })
        } else {
          this.$message.error(res.message);
        }
      })
    }
  }
};
</script>

<style scoped>
.social-login-wrapper a {
  text-decoration: none;
}
</style>
