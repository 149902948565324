import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: resolve => require(["../views/home/Home.vue"], resolve)
  },
  {
    path: "/404",
    component: resolve => require(["../views/404.vue"], resolve)
  },
  {
    path: "/account",
    component: resolve => require(["../components/model/account.vue"], resolve)
  },
  {
    path: "/articles/:articleId",
    component: resolve => require(["../views/article/Article.vue"], resolve)
  },
  {
    path: "/archives/:userId",
    component: resolve => require(["../views/archive/Archive.vue"], resolve),
    meta: {
      title: "个人归档"
    }
  },
  {
    path: "/albums",
    component: resolve => require(["../views/album/Album.vue"], resolve),
    meta: {
      title: "相册"
    }
  },
  /*{
    path: "/albums/:albumId",
    component: resolve => require(["../views/album/Photo.vue"], resolve)
  },*/
  {
    path: "/tags",
    component: resolve => require(["../views/tag/Tag.vue"], resolve),
    meta: {
      title: "标签"
    }
  },
  {
    path: "/categories",
    component: resolve => require(["../views/category/Category.vue"], resolve),
    meta: {
      title: "西瓜博客-分类市场"
    }
  },
  {
    path: "/user_categories/:userId",
    component: resolve => require(["../views/category/user_category.vue"], resolve),
    meta: {
      title: "西瓜博客-个人分类"
    }
  },
  {
    path: "/user/favorite/:userId",
    component: resolve => require(["@/views/user/favorite.vue"], resolve),
    meta: {
      title: "西瓜博客-个人收藏"
    }
  },
  {
    path: "/categories/:categoryId",
    component: resolve => require(["../views/article/ArticleList.vue"], resolve),
    meta: {
      title: "西瓜博客-分类文章"
    }
  },
  {
    path: "/organization/:organizationId",
    component: resolve => require(["@/views/organization"], resolve),
    meta: {
      title: "西瓜博客-组织"
    }
  },
  {
    path: "/fans/:userId",
    component: resolve => require(["@/views/fans"], resolve),
    meta: {
      title: "西瓜博客-粉丝列表"
    }
  },
  {
    path: "/fans/detail/:fansId",
    component: resolve => require(["@/views/fans/detail.vue"], resolve),
    meta: {
      title: "西瓜博客-粉丝详情"
    }
  },
  {
    path: "/tags/:tagId",
    component: resolve => require(["../views/article/ArticleList.vue"], resolve)
  },
  {
    path: "/links",
    component: resolve => require(["../views/link/Link.vue"], resolve),
    meta: {
      title: "友链列表"
    }
  },
  {
    path: "/about",
    component: resolve => require(["../views/about/About.vue"], resolve),
    meta: {
      title: "关于我"
    }
  },
  {
    path: "/message",
    component: resolve => require(["../views/message/Message.vue"], resolve),
    meta: {
      title: "留言板"
    }
  },
  /*{
    path: "/user",
    component: resolve => require(["@/views/user/index.vue"], resolve),
    meta: {
      title: "西瓜博客-个人中心"
    }
  },*/
  {
    path: "/user/:userId",
    component: resolve => require(["@/views/user/index.vue"], resolve),
    meta: {
      title: "西瓜博客-个人中心"
    }
  },

  {
    path: "/callback/qq",
    component: resolve => require(["../components/OauthLogin.vue"], resolve)
  },
  {
    path: "/callback/gitee",
    component: resolve => require(["../components/OauthLogin.vue"], resolve)
  },
  {
    path: "/callback/weibo",
    component: resolve => require(["../components/OauthLogin.vue"], resolve)
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
