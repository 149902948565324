import Cookies from "js-cookie";

const ARTICLE_LIKE_KEY = 'article_like_';
const WEB_USER_ID = 'web_user_id';
const WEB_USER_AVATAR = 'web_user_avatar';
const WEB_USER_USERNAME = 'web_user_name';
const AUTHENTICATION = 'web_Authentication';
const ARTICLE_FAVORITE_KEY = 'article_favorite_';
const ARTICLE_VIEWS_KEY = "article_views_";

// ----------------------点赞文章相关---------------------------------------
export function setArticleLike(articleId) {
    return Cookies.set(ARTICLE_LIKE_KEY + articleId, articleId)
}

export function delArticleLike(articleId) {
    return Cookies.remove(ARTICLE_LIKE_KEY + articleId)
}

export function getArticleLike(articleId) {
    return Cookies.get(ARTICLE_LIKE_KEY + articleId)
}

// ----------------------收藏文章相关---------------------------------------
export function setArticleFavorite(articleId) {
    return Cookies.set(ARTICLE_FAVORITE_KEY + articleId, articleId)
}

export function delArticleFavorite(articleId) {
    return Cookies.remove(ARTICLE_FAVORITE_KEY + articleId)
}

export function getArticleFavorite(articleId) {
    return Cookies.get(ARTICLE_FAVORITE_KEY + articleId)
}

// ----------------------文章阅读量相关---------------------------------------
export function setArticleViews(articleId) {
    return Cookies.set(ARTICLE_VIEWS_KEY + articleId, articleId, { expires: 1 / 24 })
}

export function delArticleViews(articleId) {
    return Cookies.remove(ARTICLE_VIEWS_KEY + articleId)
}

export function getArticleViews(articleId) {
    return Cookies.get(ARTICLE_VIEWS_KEY + articleId)
}

// -----------------------用户登录相关--------------------------------------

export function setAuthentication(val) {
    return Cookies.set(AUTHENTICATION, val)
}

export function getAuthentication() {
    return Cookies.get(AUTHENTICATION)
}

export function delAuthentication() {
    return Cookies.remove(AUTHENTICATION)
}

export function setUserId(val) {
    return Cookies.set(WEB_USER_ID, val)
}

export function getUserId() {
    return Cookies.get(WEB_USER_ID)
}

export function delUserId() {
    return Cookies.remove(WEB_USER_ID)
}

export function setUserName(val) {
    return Cookies.set(WEB_USER_USERNAME, val)
}

export function getUserName() {
    return Cookies.get(WEB_USER_USERNAME)
}

export function delUserName() {
    return Cookies.remove(WEB_USER_USERNAME)
}

export function setUserAvatar(val) {
    return Cookies.set(WEB_USER_AVATAR, val)
}

export function getUserAvatar() {
    return Cookies.get(WEB_USER_AVATAR)
}

export function delUserAvatar() {
    return Cookies.get(WEB_USER_AVATAR)
}
