import request from "@/utils/request";

// 获取网站配置信息
export function getWebinfo() {
    return request({
        url: '/system/api/getWebInfo',
        method: 'get'
    })
}

// 获取系统公告
export function systemNotice() {
    return request({
        url: '/system/api/systemNotice',
        method: 'get'
    })
}
