<template>
  <div>
    <el-dialog :close-on-click-modal="false" :show-close="false" width="40%" title="反馈" :visible.sync="this.$store.state.dialogFormVisible">
      <el-form :rules="rules" ref="dataForm" label-position="left" :model="form">
        <el-form-item label="反馈类型" prop="type">
          <el-radio v-model="form.type" :label="1">提需求</el-radio>
          <el-radio v-model="form.type" :label="2">提缺陷</el-radio>
          <el-radio v-model="form.type" :label="3">提优化</el-radio>
        </el-form-item>
        <el-form-item label="联系邮箱" prop="email" :label-width="formLabelWidth">
          <el-input placeholder="请填写您的联系邮箱"  v-model="form.email" />
        </el-form-item>
        <el-form-item label="需求" prop="demand" :label-width="formLabelWidth">
          <el-input placeholder="请用一句话描述你的需求"  v-model="form.demand"/>
        </el-form-item>
        <div class="contentInput">
          <el-form-item label="需求简述" prop="demandDesc" :label-width="formLabelWidth">
            <el-input  placeholder="请在此详细描述你的需求" :rows="6" type="textarea" v-model="form.demandDesc" :label-width="formLabelWidth" />
            <!-- <a @click="screenshot" v-if="!form.imgUrl" style="color: #03a9f4"><i class="el-icon-camera"></i> 截取页面</a>
            <img v-else :src="form.imgUrl"> -->
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {addFeedback} from '../api';
import api from "../api/user";
export default {
  metaInfo:{
    meta: [{
      name: 'keyWords',
      content: "98k灬,www.r2coding.cn,博客,个人博客,开源博客"  // 变量或字符串
    }, {
      name: 'description',
      content: "一个专注于技术分享的博客平台,大家以共同学习,乐于分享,拥抱开源的价值观进行学习交流"
    }]
  },
  data: function() {
    return {
      formLabelWidth:"80px",
      form:{
        type: 1,
        email: null,
        title: null,
        demand: null,
        demandDesc: null
      },
      rules:{
        type: [
          { required: true, message: '必填字段', trigger: 'blur' },
        ],
        email:[
          { required: true,message: '必填字段',trigger: 'blur'},
          {pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/, message: '填写正确的邮箱'}
        ],
        demand: [
          { required: true, message: '必填字段', trigger: 'blur' },
        ]
      },
    };
  },
  methods: {
    screenshot(){
      this.$toast({ type: "error", message: "敬请期待!" });
    },
    closeDialog(){
      this.form.type = 1
      this.form.email = null
      this.form.demand = null
      this.form.demandDesc = null
      this.$store.commit("setDialogFormVisible")
    },
    submit(){
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          api.saveOpinion(this.form).then(res => {
            if (res.code === 200) {
              this.$toast({ type: "success", message: "提交反馈成功" });
              this.closeDialog();
            } else {
              this.$toast({ type: "error", message: "提交反馈失败" });
            }
          });
        } else {
          this.$toast({ type: "error", message: "存在必填字段未填" });
        }
      })
    },
  },
};
</script>
